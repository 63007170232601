import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Navbar as NavbarReact, Nav } from "react-bootstrap";
import { facebook, twitter } from "data/social";
import { links } from "data/links";
import { useResizeNavbar } from "./hooks";

function Navbar() {
    const { pathname } = useLocation();
    const { navbar, image } = useResizeNavbar();

    return (
        <Container fluid className="fixed-top px-0 wow fadeIn" data-wow-delay="0.1s" ref={navbar}>

            <NavbarReact expand='lg' className="py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
                <NavbarReact.Brand as={Link} to='/' className="ms-4 ms-lg-0" >
                    <img ref={image} src='/img/logo.png' alt="logo" className="m-0" style={{ width: '290px' }} />
                </NavbarReact.Brand>

                <NavbarReact.Toggle className="m-4">
                    <span className="navbar-toggler-icon"></span>
                </NavbarReact.Toggle>

                <NavbarReact.Collapse>
                    <Nav className="ms-auto p-4 p-lg-0">
                        {links.map((enlace, i) => (
                            <Nav.Link key={i.toString()} as={Link} to={enlace.link} className={pathname === enlace.link ? 'active' : ''}>{enlace.name}</Nav.Link>
                        ))}
                        <Nav.Link href="https://sistema.sucrenet.com.ve">Area de Clientes</Nav.Link>
                        <Nav.Link href="https://bio.link/sucrenet">Autogestión</Nav.Link>
                    </Nav>
                    <div className="d-none d-lg-flex ms-2">
                        <a className="btn btn-light btn-sm-square rounded-circle ms-3" href={facebook} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={['fab', 'facebook-f']} className="text-primary" />
                        </a>
                        <a className="btn btn-light btn-sm-square rounded-circle ms-3" href={twitter} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={['fab', 'twitter']} className="text-primary" />
                        </a>
                    </div>
                </NavbarReact.Collapse>
            </NavbarReact>
        </Container>
    );
}

export default Navbar;

/*
<NavDropdown
    title="Clientes"
    show={show}
    onMouseEnter={() => setShow(true)}
    onMouseLeave={() => setShow(false)}
>
    {intranet.map((enlace, i) => (
        <NavDropdown.Item key={i.toString()} as={Link} to={enlace.link}>{enlace.name}</NavDropdown.Item>
    ))}
</NavDropdown>
*/